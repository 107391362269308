
@tailwind base;
@tailwind components;
@tailwind utilities;

  
@font-face {
    font-family: Bebas;
    font-weight: 900;
    src: url('../fonts/Bebas/Bebas-Neue-Pro-Bold.otf') format('openType');
}

@font-face {
    font-family: Bebas;
    font-weight: 800;
    src: url("../fonts/Bebas/Bebas-Neue-Pro-Bold.otf") format('opentype');
    }

  @font-face {
    font-family: Bebas;
    font-weight: 700;
    src: url("../fonts/Bebas/Bebas-Neue-Pro-Bold.otf") format('opentype');
  }

  @font-face {
    font-family: Bebas;
    font-weight: 600;
    src: url("../fonts/Bebas/Bebas-Neue-Pro-Bold.otf") format('opentype');
  }

  @font-face {
    font-family: Bebas;
    font-weight: 500;
    src: url("../fonts/Bebas/Bebas-Neue-Pro-Regular.otf") format('opentype');
  }

  // default font-weight
  @font-face {
    font-family: Bebas;
    font-weight: 400;
    src: url("../fonts/Bebas/Bebas-Neue-Pro-Regular.otf") format('opentype');
  }

  @font-face {
    font-family: Bebas;
    font-weight: 300;
    src: url("../fonts/Bebas/Bebas-Neue-Pro-Book.otf") format('opentype');
  }

  @font-face {
    font-family: Bebas;
    font-weight: 200;
    src: url("../fonts/Bebas/Bebas-Neue-Pro-Regular.otf") format('opentype');
  }

  @font-face {
    font-family: Bebas;
    font-weight: 100;
    src: url("../fonts/Bebas/Bebas-Neue-Pro-Thin.otf") format('opentype');
  }

  /* Scroll bar */

/* width */
*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
*::-webkit-scrollbar-track {
  background-color: lightgray;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #c61414;
  border-radius: 20px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}