.MembershipCardGroup {
}

.slick-slide > div {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.slick-track {
    // display: flex;
    // gap: 2rem;
}